.portfolioMain {
   display: flex;
   max-height: 100%;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: center;
   align-items: flex-start;
   width: 100%;
   
   padding: 0;
   margin: 0;
   align-self: flex-start;
   overflow: auto;
}
@media screen and (min-width: 1900px) {
   .portfolioMain {
    max-width: 100%;
   }
 }

::-webkit-scrollbar {
   width: 20px;
   
 }
 @media screen and (max-width: 600px) {
   ::-webkit-scrollbar {
      width: 0px;
      
    }
 }
 /* Track */
 ::-webkit-scrollbar-track {
   box-shadow: inset 1px 0px 0px black; 
   border-radius: 10px;
  
 }
  
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: black; 
   border-radius: 10px;
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #357242; 
 }
.portfolioItem {
   margin: .5rem;
   
   width: 340px;
   padding: 1rem;
   border-radius: 1rem;
  border: 3px solid black;
   float: right;
}

.portfolioItemTitle{
   font-size: 1.5rem;
   padding-left: 1rem;
   font-weight: 1;
}
.portfolioImg{
   float: left;
   width: 53px;
  
   height: auto;
}
.portfolioImgTall{
   float: left;
   width: auto;
   height: 100px ;
   width: 43px;
   padding-left: 10px;
}

.portfolioP{
  padding: .5rem;
  padding-left: 1rem;
  max-width: 280px;
  font-size: 1.1rem;
  font-weight: semi-bold;
}




.portfolioLink{
   float: right;
   position: relative;
   margin-right: 4rem;
   font-size: 1.5rem;
   
   border: 5px solid black;
   padding: .25rem;
   padding-left: .5rem;
   padding-right: .5rem;
   border-radius: 10px;
   color: black;
   font-weight: bold;
   text-decoration: none;

}
.blankSpace {
   height: 200px;
   width: 100%;
   opacity: 0;
}