
*{padding: 0;margin:0;overflow:hidden; font-family: 'Arvo', 'serif'}
.screen {
  height: 100vh;
  width: 100%;
  background-color: #053;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 'ease-in 5s';
}

.activePage {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation-name: slidein;
  animation-duration: .5s;
  /* overflow: auto; */
}
.closePage {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  animation-name: slideout;
  animation-duration: .5s;
}
.crossPageContainer {
  
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  animation-name: slideacross;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-delay: 60s;
}
.crossPage{
  position: fixed;
  height: 100px;
  top: 0;
}
/* The animation code */
@keyframes slidein {
  from {
    transform: translateX(100%) ;

  }

  to {
    transform: translateX(0%);
  }
}
@keyframes slideout {
  from {
    transform: translateX(0%);
  }
  
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideacross {
  from {
    transform: ;
    
  }
 
  to {
    transform:  translateY(80%) scale(4) rotate(5deg) ;
    


  }
}
/* @keyframes slideacross {
  from {
    transform: translateX(100%) translateY(100%) scale(1);
    
  }
  50% {
    transform: translateX(0%) translateY(60%) scale(3)
  }
  90% {
    transform: translateX(0%) translateY(250%) scale(4.5)

  }
  to {
    transform: translateX(0%) translateY(500%) scale(6)

  }
} */
.gallery {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  margin-bottom: 100px;
  max-width: 100%;
}
.galleryFrame {
  width: 360px;
  height: 360px;
  margin-bottom: 1rem;
  border: 3px solid black;
  border-radius: 1rem;
}
.galleryImage {
  max-width: 360px;
  
  
  border-radius: 1rem;
 
}
.title{
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
.main {
  display: flex;
  max-height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  
  padding: 0;
  margin: 0;
  align-self: flex-start;
  overflow: auto;
}
.lastItem{
  margin-bottom: 3rem;
}
@media screen and (max-width: 600px) {
  .gallery {
   flex-wrap: wrap;
  }
}